import { ITrackResultDto, OrderStageType, SaleStageType } from "@lib";
import { BButton, BFormInput, BInputGroup, BInputGroupPrepend, BCard, BCardTitle, BBadge } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";
import AltLogo from "@/core/components/alt-logo";
import { ApiError } from "@/core/exceptions";
import { getOrderStageColor, getOrderStageText } from "@/views/main/orders/orders-defaults";
import { getSaleStageColor, getSaleStageText } from "@/views/main/sales/sales-defaults";

@Component({
    name: "tracking",
    components: {
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BButton,
        BCard,
        BCardTitle,
        BBadge,
        ValidationProvider,
        ValidationObserver,
        AltLogo,
    },
})
export default class Tracking extends Vue {
    private TrackingUseCase = this.$alt.system.usecase.createTrackingUseCase();

    private resources = {
        TitleText: "Поиск по трек-номеру",
        LabelTrackingCode: "Трек-номер",
        ButtonStart: "Искать",
        ButtonBack: "Назад",
        ValidatorId: "trackingCodeValidator",
    };

    private code: string = "";
    private trackError: string | null = null;
    private trackResult: ITrackResultDto | null = null;

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();

            this.code = this.$router.currentRoute.params.code;
            if (!this.code) {
                return;
            }

            await this.start();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async start(): Promise<boolean> {
        try {
            if (this.code.length === 0) {
                return false;
            }

            this.trackResult = await this.TrackingUseCase.track(this.code);
            return true;
        } catch (e: any) {
            if (e.code === ApiError.TRACKING_CODE_NOT_FOUND) {
                this.trackError = "Трек-номер не найден.";
            } else {
                this.trackError = e.message;
            }
            return false;
        }
    }

    private back(): void {
        this.code = "";
        this.trackResult = null;
        this.trackError = null;
        this.$router.replace({ name: "tracking" });
    }

    private async onClickStart(): Promise<void> {
        try {
            const valid = await (this.$refs[this.resources.ValidatorId] as any).validate();
            if (!valid) {
                return;
            }

            this.$alt.loader.show();

            await this.start();
            this.$router.push({ name: "tracking", params: { code: this.code } });
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private getStageColor(trackResult: ITrackResultDto): string | undefined {
        if (trackResult.type === "order") {
            return getOrderStageColor(trackResult.info.stage as OrderStageType);
        }

        if (trackResult.type === "sale") {
            return getSaleStageColor(trackResult.info.stage as SaleStageType);
        }

        return "danger";
    }

    private getStageText(trackResult: ITrackResultDto): string | undefined {
        if (trackResult.type === "order") {
            return getOrderStageText(trackResult.info.stage as OrderStageType);
        }

        if (trackResult.type === "sale") {
            return getSaleStageText(trackResult.info.stage as SaleStageType);
        }

        return "Неизвестно";
    }
}
